tbody {
    --bs-table-bg: rgba(247, 247, 248, 1);
}

thead {
    --bs-table-bg: rgba(196, 196, 196, 1);
}

body {
    font-family: 'General Sans', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !important;
    background: #e1dad4 !important;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.offcanvas.offcanvas-end {
    width: 70% !important;
}

.timeline_area {
    position: relative;
    z-index: 1;
}

.single-timeline-area {
    position: relative;
    z-index: 1;
    padding-left: 180px;
}

.single-timeline-area .timeline-date {
    position: absolute;
    width: 180px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 60px;
}



.single-timeline-area .timeline-date::after {
    position: absolute;
    width: 1px;
    height: 100%;
    content: "";
    background-color: #ebebeb;
    top: 0;
    right: 30px;
    z-index: 1;
}

.single-timeline-area .timeline-date::before {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #0DAE56;
    content: "";
    top: 50%;
    right: 26px;
    z-index: 5;
    margin-top: -5.5px;
}

.single-timeline-area .timeline-date p {
    margin-bottom: 0;
    color: #020710;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}

.single-timeline-area .single-timeline-content {
    position: relative;
    z-index: 1;
    padding: 30px 30px 25px;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-top: 15px;
    -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
    border: 1px solid #ebebeb;
}


.single-timeline-area .single-timeline-content .timeline-icon {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    width: 30px;
    height: 30px;
    background-color: #36528B;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    text-align: center;
    /* max-width: 30px; */
    border-radius: 50%;
    margin-right: 15px;
}

.single-timeline-area .single-timeline-content .timeline-icon i {
    color: #ffffff;
    line-height: 30px;
}

.single-timeline-area .single-timeline-content .timeline-text h6 {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-timeline-area .single-timeline-content .timeline-text p {
    font-size: 13px;
    margin-bottom: 0;
}

.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
    background-color: #020710;
}

.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
    color: #3f43fd;
}

.contact-icon-container {
    /*  width: 8%; */
    margin-right: 1rem;
}

.contact-icon h5 {
    font-weight: 800;
}

.contact-icon-box {

    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    color: #ffffff;
    background: #36528B;
}

.contact-icon-box svg {
    text-align: center;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    position: relative;
    top: 5px;
}

.contact-title {
    /* margin-top: auto;
    margin-bottom: auto; */
    align-items: center;
    display: flex;
    color: #000;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.navbar-toggler:focus {

    box-shadow: none !important;
}



@media only screen and (max-width: 575px) {}

@media only screen and (max-width: 575px) {

    .single-timeline-area .single-timeline-content {
        padding: 20px;
    }

    .single-timeline-area .timeline-date {
        width: 110px;
        font-size: 12px;
    }

    .single-timeline-area {
        padding-left: 100px;
    }

    .custom-heading-shadow {
        /* display: none; */
    }

    .contact-icon .contact-icon-container {
        /*   width: 20%; */
    }

    .contact-left {
        margin-bottom: 50px;
    }
}

.row .no-x-gutter {
    --bs-gutter-x: 0rem;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* sliding nav */
.sticky {
    color: white;
    background: rgba(13, 174, 86, 0.48);
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08);
    height: auto;
    opacity: 0.9;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 999
}

.sliding-nav {
    transition: 0.3s all linear;
    color: black !important;
    /* background: rgba(13, 174, 86, 0.48); */
    box-shadow: rgba(18, 18, 18, 0.08) 0px 4px 40px 0px;
    height: auto;
    /* opacity: 0.9; */
    width: 100vw;
    position: fixed;
    top: 0px;
    z-index: 999;
    background: #a6f7a6;
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08);
}

.custom-button {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: 700;
}

.custom-button.primary {
    background-color: #0DAE56;
    border: 1px solid #0b9f4d;
    color: #ffffff;
    font-weight: 700;
}

.custom-button.primary:hover,
.custom-button.primary:focus {
    border: 1px solid #0a8542 !important;
    background: #0b9f4d !important;
    color: #ffffff;
}


.custom-button.secondary:hover,
.custom-button.secondary:focus {
    color: #0DAE56 !important;
    border: 1px solid #0DAE56 !important;
    background-color: #f4fff9 !important;
    font-weight: 700;
}


.custom-button.secondary {
    color: #0DAE56;
    border: 1px solid #0DAE56;
    background-color: #ffffff;
    font-weight: 700;
}

.custom-button.secondary:hover {
    background: #0b9f4d;
    color: #000;
}

.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    z-index: 10000 !important;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.back-to-top-button.visible {
    opacity: 1;
    visibility: visible;
}


button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}


.border-right {
    border-right: 2px solid #dee2e6 !important;
}

.dropdown-big-custom:hover,
.dropdown-big-custom:focus,
.dropdown-big-custom.active-nav {
    border-left: 2px solid #0DAE56;
    background: rgba(13, 174, 86, 0.08) !important;
    color: #0DAE56 !important;
}

.swiper-pagination-progressbar {
    background: rgba(251, 169, 29, 0.16) !important;
}

.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    position: inherit !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #0DAE56 !important;
}

.gallery-pagination ul {
    gap: 20px
}

.gallery-pagination ul li.active {
    color: #0a8542 !important;
    font-weight: 700;
}

.image-child {
    height: 210px;
    width: 100%;
    object-fit: cover;
    padding: 10px;
}

.image-mansonry {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
}

/* Main CSS */
.grid-wrapper>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-wrapper>div>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.grid-wrapper {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-auto-flow: dense;
}

.grid-wrapper .wide {
    grid-column: span 2;
}

.grid-wrapper .tall {
    grid-row: span 2;
}

.grid-wrapper .big {
    grid-column: span 2;
    grid-row: span 2;
}

.nav-lang .dropdown-menu {
    min-width: 50px !important;
}

.img-activity {
    position: relative;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.img-activity .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .5s;
    overflow: hidden;
    height: 25%;
    background: rgba(54, 54, 54, 0.7);
}

.img-activity .overlay .caption-title p {
    display: none;
}

.img-activity:hover .overlay,
.img-activity:hover .overlay .caption-title p,
.overlay:hover {
    height: 100%;
    display: block;
    background: rgba(54, 54, 54, 0.9);
}

.img-activity>img {
    display: block;
    /* Prevent inline gap under image*/
}